import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { first } from 'rxjs/operators';
import { SYSTEM_ROLES } from '../core.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  claims: any = null;
  _currentUser: firebase.default.User = null;

  constructor(public auth: AngularFireAuth) {
  }

  /**
   * Logs the user in using their Email/Password combo
   * 
   * 
   * @param email
   * @param password
   * @returns {firebase.Promise<FirebaseAuthState>}
   */
  async loginWithEmail(email, password): Promise<any> {
    let user = null;
    try {
      const authResult = await this.auth.signInWithEmailAndPassword(email, password);
      if ( authResult && authResult.user ) {
        this._currentUser = authResult.user;
        const idTokenResult = await this._currentUser.getIdTokenResult();
        this.claims = idTokenResult.claims;
      }
    } catch(e) {
      throw e;
    }
    return user;
  }
  
  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email).catch(err => Promise.reject(err));
  }

  public signOut(): void {
    this.auth.signOut().catch(err => Promise.reject(err));
  }

  public addAuthUser(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email,password).catch(err => Promise.reject(err));
  }

  public changePassword(password: string):Promise<any> {
    if ( !firebase.default.auth().currentUser ) {
      throw new Error('Unable to change password, no current user is authenticated.');
    }
    return firebase.default.auth().currentUser.updatePassword(password).catch(err => Promise.reject(err));
  }

  public getLoggedInUser(): Promise<firebase.default.User> {
    if ( this._currentUser ) return Promise.resolve(this._currentUser);
    return this.auth.authState.pipe(first()).toPromise().then(user => {
      this._currentUser = user;
      return Promise.resolve(user);
    }).catch(err => Promise.reject(err));
  }

  public async getAuthId(): Promise<string> {
     try{
      const user = await this.getLoggedInUser();
      if ( user ) {
        return user.uid;
      }
      return null;
     } catch(e) {
       throw e;
     }
  }


  public async getAppUserId() {
    if ( this.claims ) {
      return this.claims.appUserId;
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        return this.claims.appUserId;
      }
      return null;
    } catch(e) {
      throw e;
    }
  }

  public async getCustomerKey() {
    if ( this.claims ) {
      return this.claims.customerKey;
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        return this.claims.customerKey;
      }
      return null;
    } catch(e) {
      throw e;
    }
  }

  public async loggedInUserHasRole(role: string): Promise<boolean> {
    if ( this.claims ) {
      return this.claims[role];
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        return idTokenResult.claims[role];
      }
      return null;
    } catch(e) {
      throw e;
    }
  }

  public async getAllUserRoles(SYSTEM_ROLES: any): Promise<Array<string>> {
    try {
      if ( !this.claims ) {
        const user = await this.getLoggedInUser();
        if ( user ) {
          const idTokenResult = await user.getIdTokenResult();
          this.claims = idTokenResult.claims;
        } 
      }
      const allUserRoles = [];
      if ( this.claims ) {
        Object.keys(SYSTEM_ROLES).forEach(key => {
          if ( this.claims[SYSTEM_ROLES[key]] ) {
            allUserRoles.push(SYSTEM_ROLES[key]);
          }
        });
      }
      return allUserRoles;
    } catch(e) {
      return e;
    }
  }
  
  public async hasAllRoles(roles): Promise<boolean> {
    if ( this.claims ) {
      return this._checkRoles(roles, this.claims)
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        this._checkRoles(roles, this.claims);
      }
      return false;
    } catch(e) {
      throw e;
    }
  }

  private _checkRoles(roles, claims) {
    let count = 0;
      roles.forEach( r => {
        if ( claims[r] ) count++;
      });
      return count === roles.length;
  }

  public async isAdmin(): Promise<boolean> {
    if ( this.claims ) {
      return this.claims[SYSTEM_ROLES.administrator];
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        return idTokenResult.claims[SYSTEM_ROLES.administrator];
      }
      return null;
    } catch(e) {
      throw e;
    }
  }

  public async isSystem(): Promise<boolean> {
    if ( this.claims ) {
      return this.claims[SYSTEM_ROLES.system];
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        return idTokenResult.claims[SYSTEM_ROLES.system];
      }
      return null;
    } catch(e) {
      throw e;
    }
  }

  public async isActionCenter(): Promise<boolean> {
    if ( this.claims ) {
      return this.claims[SYSTEM_ROLES.actionCenterUser];
    }
    try {
      const user = await this.getLoggedInUser();
      if ( user ) {
        const idTokenResult = await user.getIdTokenResult();
        this.claims = idTokenResult.claims;
        return idTokenResult.claims[SYSTEM_ROLES.actionCenterUser];
      }
      return null;
    } catch(e) {
      throw e;
    }
  }
  
}
