import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';

import { routing }       from './pages.routing';
import { NgaModule } from '../theme/nga.module';
import { AppTranslationModule } from '../app.translation.module';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { Pages } from './pages.component';

@NgModule({
    imports: [CommonModule, AppTranslationModule, NgaModule, routing, AngularFireAnalyticsModule],
    providers: [
      ScreenTrackingService,
      UserTrackingService
    ], 
  declarations: [Pages]
})
export class PagesModule {
  
}
