<div class="menu-container">
    <ul id="al-sidebar-list" class="inner-scroll-menu al-sidebar-list">
    <ba-menu-item
            [menuItem]="item" (toggleSubMenu)="toggleSubMenu($event)" *ngFor="let item of menuItems">
        </ba-menu-item>
    </ul>
</div>

<!--
<ul id="al-sidebar-list" class="al-sidebar-list" baSlimScroll [baSlimScrollOptions]="{height: menuHeight}"
    (mouseleave)="hoverElemTop=outOfArea">
  <ba-menu-item
    [menuItem]="item"
    (itemHover)="hoverItem($event)"
    (toggleSubMenu)="toggleSubMenu($event)"
    *ngFor="let item of menuItems"></ba-menu-item>
</ul>
<div class="sidebar-hover-elem" [ngStyle]="{top: hoverElemTop + 'px', height: hoverElemHeight + 'px'}"
     [ngClass]="{'show-hover-elem': showHoverElem }">
</div>
-->