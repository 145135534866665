import { Routes, RouterModule } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../providers/auth.guard';
import { RoleGuard } from '../providers/role.guard';
import { SYSTEM_ROLES } from 'app/c2-core/core.constants';
import { RedirectGuard } from 'app/providers/redirect.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('app/pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register/:ck/:id',
    loadChildren: () => import('app/pages/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full',
  },
  {
    path: 'pages',
    component: Pages,
    canActivate: [AuthGuard],
    children: [
        // RedirectGuard will send the user to actions or dashboard depending on role
      { path: '', component: Pages, pathMatch: 'full', canActivate: [RedirectGuard]},
      { path: 'actions', loadChildren: () => import('./actions/actions.module').then(m => m.ActionsModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.actionCenterUser] } },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] } },
      { path: 'policies', loadChildren: () => import('./policies/policies.module').then(m => m.PoliciesModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] } },
      { path: 'documents', loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] }},
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] } },
      { path: 'assignments', loadChildren: () => import('./assignments/assignments.module').then(m => m.AssignmentsModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] } },
      { path: 'procedures', loadChildren: () => import('./procedures/procedures.module').then(m => m.ProceduresModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] } },
      { path: 'training', loadChildren: () => import('./training/training.module').then(m => m.TrainingModule),
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.administrator] }},
      { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule), 
          canActivate: [RoleGuard], data: { roles: [SYSTEM_ROLES.system] }},
      {
        path: 'register',
        loadChildren: () => import('app/pages/register/register.module').then(m => m.RegisterModule)
      },
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
