export class SubscriptionManager {
    subscriptions: Array<any> = new Array<any>();
    constructor() {}

    set next(sub) {
        this.subscriptions.push(sub);
    }

    stop() {
        this.subscriptions.forEach(s => {
            if (s) s.unsubscribe();
        })
    }

}
