import { ErrorHandler, InjectionToken, Injectable, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';
import { UserService } from './c2-core/apis/user/services/user.service';
import swal  from 'sweetalert2';
import { LogService } from './c2-core/services/log.service';

@Injectable()
export class C2ErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private userService: UserService, private logger: LogService) {}

  async handleError(err: any) {
    if ( this.checkForNetworkError(err) ) {
      return;
    }

    if ( this.checkForChunkLoadError(err) ) {
      return;
    }

    const user = await this.userService.findUserProfile();
    var rollbar = this.injector.get(RollbarService);
    rollbar.configure({payload: {
      person: {
        id: user.id, 
        username: user.email,
        email:user.email,
      }
    }})
    rollbar.error(err.originalError || err);
    this.logger.error(err);
    swal.fire('An error occured while performing your request.', '<br>' + (err.c2Message || err.message) + '<br><br>', 'error');
  }

  checkForNetworkError(err: any) {
    const toast = swal.mixin({
      toast: true,
      icon: 'error',
      position: 'top',
      background: '#F95372',
      title: '<div style="color: #292929">Offline</div>',
      html:'<div style="color: #292929">No internet connection available.  Please make sure your device is connected to the Internet.</div>',
      showConfirmButton: false,
      timer: 9000
    });
    
    if ( err.message && err.message.includes('client is offline') ) {
      toast.fire();
      return true;
    }
    return false;
  }

  checkForChunkLoadError(err: any) {
    const toast = swal.mixin({
      toast: true,
      icon: 'info',
      position: 'top',
      title: '<div style="color: #292929">New Version</div>',
      html:'<div style="color: #292929">A new version is available, click to <a href=".">refresh</a></div>',
      showConfirmButton: false,
    });
    if ( err.message && err.message.includes('Loading chunk') ) {
      toast.fire();
      return true;
    }
    return false;
  }
}

export function rollbarFactory() {
  return new Rollbar(environment.rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');