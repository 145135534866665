import { BaseModel } from '../../base/base.model';

export class CustomerLocation extends BaseModel {
    id: string;
    primaryName: string;
    secondaryName: string = null;
    code: string = null;
    countryCode: string = 'US'; //ISO 3166-1 alpha-2
    address1: string;
    address2: string = null;
    city: string;
    state: string;
    postalCode: string;

    constructor(user: any) {
        super();
        this.copy(user);
    }

    copy(that: any) {
        if ( that != null && that !== undefined ) {
            this.id = that.id || null;
            this.primaryName = that.primaryName || null;
            this.secondaryName = that.secondaryName || null;
            this.code = that.code || null;
            this.countryCode  = that.countryCode || null;
            this.address1 = that.address1 || null;
            this.address2 = that.address2 || null;
            this.city = that.city || null;
            this.state = that.state || null;
            this.postalCode = that.postalCode || null;
        } else {
        }
    }


   
    toJSON() {
        return {
            id: this.id,
            primaryName: this.primaryName,
            secondaryName: this.secondaryName,
            code: this.code,
            countryCode: this.countryCode,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            postalCode: this.postalCode,
        };
    }
}