import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from './providers/auth.guard';

export const routes: Routes = [
  {
    path: 'logout', canActivate: [AuthGuard],
    loadChildren: () => import('app/logout/logout.module').then(m => m.LogoutModule)
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full',
      canActivate: [
        AuthGuard,
      ],
  },
  { path: '**', redirectTo: 'pages', 
      canActivate: [
        AuthGuard,
      ],
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' });