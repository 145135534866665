import { BaseModel } from '../../base/base.model';

export class UserFileLedger extends BaseModel {
    id: string;
    fileName: string;
    customerKey: string;
    chunkSize: number;
    chunks: number;
    records: number;
    createdOn: Date;
    completedOn: Date;
    uploadedBy: string;
    updated: number;
    inserted: number;
    reinstated: number;
    disabled: number;
    deleted: number;
    totalTerms: number;
    errored: number;
    noop: number;
    totalRecordsProcessed: number;
    userName: string;

    constructor(ledger: any) {
        super();
        this.copy(ledger);
    }

    copy(that: any) {
        if ( that != null && that !== undefined ) {
            this.id  = super.safeValueOrNull(that.id);
            this.fileName = super.safeValueOrNull(that.fileName);
            this.customerKey = super.safeValueOrNull(that.customerKey);
            this.chunkSize = super.safeValueOrNull(that.chunkSize);
            this.chunks = super.safeValueOrNull(that.chunks);
            this.records = super.safeValueOrNull(that.records);
            this.createdOn = super.fromFirestoreDate(that.createdOn);
            this.completedOn = super.fromFirestoreDate(that.completedOn);
            this.uploadedBy = super.safeValueOrNull(that.uploadedBy);
            this.updated = super.safeValueOrNull(that.updated);
            this.inserted = super.safeValueOrNull(that.inserted);
            this.reinstated = super.safeValueOrNull(that.reinstated);
            this.disabled = super.safeValueOrNull(that.disabled);
            this.deleted = super.safeValueOrNull(that.deleted);
            this.totalTerms = super.safeValueOrNull(that.totalTerms);
            this.errored = super.safeValueOrNull(that.errored);
            this.noop = super.safeValueOrNull(that.noop);
            this.totalRecordsProcessed = super.safeValueOrNull(that.totalRecordsProcessed);
            this.userName = super.safeValueOrNull(that.userName);
        }
    }

    toJSON() {
        return {
            fileName: this.fileName,
            customerKey: this.customerKey,
            chunkSize: this.chunkSize,
            chunks: this.chunks || 0,
            records: this.records,
            createdOn: this.createdOn,
            completedOn: null, 
            uploadedBy: this.userName,
            updated: this.updated || 0,
            inserted: this.inserted || 0,
            reinstated: this.reinstated || 0,
            disabled: this.disabled || 0,
            deleted: this.deleted || 0,
            totalTerms: this.totalTerms || 0, // disabled + deleted
            errored: this.errored || 0,
            noop: this.noop || 0,
            totalRecordsProcessed: this.totalRecordsProcessed || 0,
        }
    }

}
