import { NgModule } from '@angular/core';
//import { Http, HttpModule } from '@angular/http';

export function createTranslateLoader() {
    return null;//new TranslateHttpLoader(http, './assets/i18n/US/', '.json');
}

/*
const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [Http]
  }
};
*/

@NgModule({
  //imports: [TranslateModule.forRoot(translationOptions)],
  //exports: [TranslateModule],
  //providers: [TranslateService]
})
export class AppTranslationModule {
  constructor() {
    //translate.addLangs(["en"]);
    //translate.setDefaultLang('en');
    //translate.use('en');
  }
}
