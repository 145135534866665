import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { DataService } from '../../../services/data.service';
import { BaseService } from '../../base/base.service';
import { UserService } from './user.service';

import { UserFileLedger } from '../models/user-file-ledger.model';
import { DistributionList } from '../models/distribution-list.model';

import * as Constants from '../../../core.constants';
import { UserStage } from '../models/user-stage.model';

/**
 * Service provides methods to synchronize users through uploaded files
 * and manage distribution (user/contacts) lists.
 * 
 */
@Injectable()
export class SynchService extends BaseService {
    readonly DISTRIBUTION_LIST_ALL: string = 'All';
    readonly CHUNK_SIZE: number = 50;

    constructor(private dataService: DataService, private userService: UserService) {
        super();
    } 

    /**
     * Returns all file ledgers for a customer
     * 
     * 
     * @param customerKey 
     */
    public listLedgers(customerKey: string): Observable<Array<UserFileLedger>> {
        return this.dataService.fetch<UserFileLedger>(this.dataService.COLLECTION_USER_FILE_LEDGER, (ref) => {
            return ref.where('customerKey', '==', customerKey)
                .orderBy('createdOn', "desc")
        }).pipe(map(list => super.mapList(list, UserFileLedger)));
    }
    
    /**
     * Find a file ledger
     * 
     * @param ledgerId 
     */
    public findLedger(ledgerId: string): Observable<UserFileLedger> {
        const path = this.dataService.COLLECTION_USER_FILE_LEDGER + ledgerId;
        return this.dataService.getDocument(path).pipe(map(doc => super.mapObject(doc, UserFileLedger, path)));
    }

    /**
     * List stage records for a ledger
     * 
     * @param ledgerId 
     * @param customerKey 
     */
    public listStageRecords(ledgerId: string, customerKey: string): Observable<Array<UserStage>> {
        const path = this.dataService.COLLECTION_USER_FILE_LEDGER + ledgerId + this.dataService.COLLECTION_USER_FILE_LEDGER_STAGE;
        return this.dataService.fetch<UserStage>(path, (ref) => {
            return ref.where('customerKey', '==', customerKey)
        }).pipe(map( list => super.mapList(list, UserStage)));
    }

    /**
     * List the errored records from the ledge stage collection
     * 
     * @param ledgerId 
     */
    public listLedgerErrors(ledgerId: string, customerKey: string): Observable<Array<UserStage>> {
        const path = this.dataService.COLLECTION_USER_FILE_LEDGER + ledgerId + this.dataService.COLLECTION_USER_FILE_LEDGER_STAGE;
        return this.dataService.fetch<UserStage>(path, (ref) => {
            return ref.where('customerKey', '==', customerKey)
                .where('operation', '==', 'errored');
        }).pipe(map( list => super.mapList(list, UserStage)));
    }s

    /**
     * Add a distribution list
     * 
     * 
     * @param customerKey 
     * @param list 
     */
    public addDistributionList(customerKey: string, list: DistributionList): Promise<any> {
        list.id = this.dataService.createId();
        list.customerKey = customerKey;
        let path = this.dataService.COLLECTION_DISTRIBUTION_LIST;
        return this.dataService.addDocument(path, list.toJSON()).catch(err => Promise.reject(err));
    }

    public addAllUsersDistributionList(customerKey: string) {
        let all = new DistributionList(null);
        all.customerKey = customerKey;
        all.id = this.dataService.createId();
        all.name  = this.DISTRIBUTION_LIST_ALL;
        all.description = "All Users";
        let path: string = this.dataService.COLLECTION_DISTRIBUTION_LIST;
        return this.dataService.addDocument(path, all.toJSON()).catch(err => Promise.reject(err));
    }

    /**
     * Finds a distrubtion list
     * 
     * 
     * @param customerKey 
     * @param listId 
     */
    public findDistributionList(customerKey: string, listId: string): Observable<DistributionList> {
        let path = this.dataService.COLLECTION_DISTRIBUTION_LIST+listId;
        return this.dataService.getDocument(path)
            .pipe(map(obj => super.mapObject(obj, DistributionList, path)));
    }

    /**
     * Delete a distribution list
     * 
     * 
     * @param list 
     */
    public deleteDistributionList(customerKey: string, list: DistributionList): Promise<any> {
        let path = this.dataService.COLLECTION_DISTRIBUTION_LIST+list.id;
        return this.dataService.deleteDocument(path)
            .catch(err => Promise.reject(err));
    }

    /**
     * Save (update) a disribution list
     * 
     * 
     * @param list 
     */
    public saveDistributionList(customerKey: string, list: DistributionList): Promise<any> {
        let path = this.dataService.COLLECTION_DISTRIBUTION_LIST+list.id;
        return this.dataService.updateDocument(path, list.toJSON()).catch(err => Promise.reject(err));
    }
   
    /**
     * Upload a CSV document of users to be processed 
     * 
     * Resolves with the number of chunks created
     */
    public stageFile(customerKey: string, fileName: string, records: any[]): Promise<void> {

        const sourcedRecords = records.length;

        // Create an ID for each record, this ID will be used
        // as the users ID if they don't already exist.
        records.forEach( r => {
            r.id = this.dataService.createId();
        });

        // create chunks out of the records
        var chunks = [];
        while (records.length) {
            chunks.push(records.splice(0, this.CHUNK_SIZE));
        }

        // Then create the file ledger for this upload
        let ledger = new UserFileLedger(document);
        ledger.fileName = fileName;
        ledger.customerKey = customerKey;
        ledger.chunkSize = this.CHUNK_SIZE;
        ledger.chunks = chunks.length;
        ledger.records = sourcedRecords;
        ledger.createdOn = new Date();
        //ledger.uploadedBy = this.userService.userName;

        const rootPath: string = this.dataService.COLLECTION_USER_FILE_LEDGER;

        return this.dataService.addDocument(rootPath, ledger.toJSON()).then( (rootDocId) => {
            const collectionRef = this.dataService.collectionRef(rootPath + rootDocId + '/chunks');
            const chunkDocs = [];
            chunks.forEach( chunk => {
                const chunkDoc = {
                    size: chunk.length,
                    records: chunk,
                    customerKey: customerKey,
                    ledgerId: rootDocId,
                }
                chunkDocs.push(chunkDoc);
            })
            return this.dataService.batchInsert(collectionRef, chunkDocs).catch(err => Promise.reject(err));
        }).catch(err => Promise.reject(err));
    }
    
    /**
     * Validate a record
     * 
     * 
     * @param record 
     */
    public validateRecord(record: any): boolean {
        return this.validateEmail(record.email);
    }

     /**
     * Validates an email column for an array of records
     * which have a column called 'email'.
     * 
     * 
     * @param records
     * 
     * @returns An array of numbers that correspond to the row number within
     * array where an email was not valid
     * 
     */
    public validateEmails(records: any[]): number[] {
        const errorRows: any[] = [];
        let index: number = 0;
        let row: number = 0;
        records.forEach( rec => {
            if ( this.validateEmail(rec.email) == false ) {
                errorRows[index++] = row;
            }
            row++;
        });
        return errorRows;
    }

    public validateEmail(email: string): boolean {
        if ( email == null || email === undefined ) {
            return false;
        }
        return new RegExp(Constants.REGEX_EMAIL).test(email);
    }
}