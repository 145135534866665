import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';

import { DataService } from '../../../services/data.service';
import { TermsOfUse } from '../models/terms-of-use.model';

import { BaseService } from '../../base/base.service';

@Injectable()
export class TermsService extends BaseService {
    constructor(private dataService: DataService) {
        super();
    }

    public listAllVersions(): Observable<Array<TermsOfUse>> {
        return this.dataService.listCollection(this.dataService.COLLECTION_TERMS_OF_USE, 'version')
            .pipe(map( list => super.mapList(list, TermsOfUse)));
    }

    public save(terms: TermsOfUse): Promise<void> {
        const path = this.dataService.COLLECTION_TERMS_OF_USE + terms.id;
        return this.dataService.updateDocument(path, terms.toJSON())
            .catch(err => Promise.reject(err));
    }
    
}