import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'app/c2-core/services/auth.service';
import { UserService } from 'app/c2-core/apis/user/services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService,  private userService: UserService, private router: Router){}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    
    return this.auth.getLoggedInUser().then( (authUser) => {
      if ( !authUser ) {
        this.router.navigate(['/login']);
        return Promise.resolve(false);  
      }
      
      return this.userService.findUserProfile().then( (appUser) => {
        if ( appUser && !appUser.registered ) {
          this.router.navigate(['/register', appUser.customerKey, appUser.id]); 
        }
        return Promise.resolve(true);
      })
    }).catch(err => Promise.reject(err))
  }
}
