import { BaseModel } from '../../base/base.model';

export class CustomerContact extends BaseModel {
    readonly TYPE_PRIMARY: string = 'Primary';
    readonly TYPE_TECHNICAL: string = 'Technical';
    readonly TYPE_BILLING: string = 'Billing';

    id: string;
    firstName: string;
    lastName: string;
    type: string;
    phone1: string;
    phone2: string;
    email: string;

    constructor(user: any) {
        super();
        this.copy(user);
    }

    copy(that: any) {
        if ( that != null && that !== undefined ) {
           this.id = that.id || null;
           this.firstName = that.firstName || null;
           this.lastName = that.lastName || null;
           this.type = that.type  || null;
           this.phone1 = that.phone1 || null;
           this.phone2 = that .phone2 || null;
           this.email = that.email || null;
        }
    }
   
    toJSON() {
        return {
          id: this.id,
          firstName: this.firstName,
          lastName: this.lastName,
          type: this.type,
          phone1: this.phone1 || null,
          phone2: this.phone2 || null,
          email: this.email || null,
        };
    }
}
