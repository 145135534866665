import { BaseModel } from '../../base/base.model';

// NOTE: A default distriubtion list will be created for all 
// customers that represents all users in the customer group

export class DistributionList extends BaseModel {
    readonly LIST_ALL: string = "All";

    name: string;
    description: string;
    roles: Array<string>;
    path: string;
    id: string;
    customerKey: string;

    constructor(target: any) {
        super();
        this.copy(target);
    }

    copy(that: any) {
        if ( that != null && that !== undefined ) {
            this.name = super.safeValueOrNull(that.name);
            this.description = super.safeValueOrNull(that.description);
            this.roles = [];
            if ( that.roles ) {
                this.roles = that.roles.slice(0);
            }
            this.id = that.id;
            this.path = super.safeValueOrNull(that.path);
            this.customerKey = that.customerKey;
        }
    }

    isSystemGenerated(): boolean {
        return this.name === this.LIST_ALL;
    }
    
    addRole(role: string) {
        if ( !this.roles ) {
            this.roles = new Array<string>();
        }
        this.roles.push(role);
    }
    
    removeRole(role: string) {
        if ( !this.roles ) {
            return;
        }

        const idx: number = this.roles.indexOf(role);
        this.roles.splice(idx, 1);
    }

    hasRole(role: string): boolean {
        if ( ! this.roles ) {
            return false;
        }
        return this.roles.indexOf(role) == -1? false : true;
    }

    /**
     * Comapres another object (that) to this
     * returns false if any field is not identical
     */
    compareTo(that: any) {
        if ( !super.compare(this.name, that.name) ) {
            return false;
        }
        
        return true;
    }

    toJSON() {
        return {
            name: this.name || null,
            description: this.description || null,
            roles: this.roles || null,
            id: this.id,
            customerKey: this.customerKey,
        };
    }
}