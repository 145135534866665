import { SYSTEM_ROLES } from 'app/c2-core/core.constants';

export const BASE_MENU = [
  {
    path: 'pages',
    children: []
  }
]

export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'dashboard',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Dashboard',
            icon: 'fa fa-tachometer',
            pathMatch: 'partial',
            selected: false,
            expanded: false,
            order: 7,
          },
        },
      },
      {
        path: 'actions',
        roles: [SYSTEM_ROLES.actionCenterUser],
        data: {
          menu: {
            title: 'Action Center',
            icon: 'ion-film-marker',
            selected: false,
            expanded: false,
            order: 100,
            pathMatch: 'partial',
          },
        },
      },
      {
        path: 'policies',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Policies',
            icon: 'fas fa-shield-alt',
            selected: false,
            expanded: false,
            order: 2,
            pathMatch: 'partial',
          },
        },
      },
      {
        path: 'procedures',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Procedures',
            icon: 'fa fa-list-ol',
            selected: false,
            expanded: false,
            order: 3,
            pathMatch: 'partial',
          },
        },
      },
      {
        path: 'documents',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Documents',
            icon: 'fa fa-file-text',
            selected: false,
            expanded: false,
            order: 2,
            pathMatch: 'partial',
          },
        },
      },
      {
        path: 'training',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Training',
            icon: 'fa fa-graduation-cap',
            selected: false,
            expanded: false,
            order: 4,
            pathMatch: 'partial',
          },
        },
      },
      {
        path: 'assignments',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Assignments',
            icon: 'ion-checkmark-circled',
            selected: false,
            expanded: false,
            order: 5,
            pathMatch: 'partial',
          },
        },
      },
      {
        path: 'settings',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Options',
            icon: 'ion-toggle-filled',
            selected: false,
            expanded: false,
            order: 5,
          },
        },
        children: [
          {
            path: 'term-library',
            data: {
              menu: {
                title: 'Terms',
              }
            }
          },
          {
            path: 'controls-library',
            data: {
              menu: {
                title: 'Policy Controls',
              }
            }
          },

        ]
      },
      {
        path: 'settings',
        roles: [SYSTEM_ROLES.administrator],
        data: {
          menu: {
            title: 'Settings',
            icon: 'ion-gear-a',
            selected: false,
            expanded: false,
            order: 5,
          },
        },
        children: [
          {
            path: 'users',
            data: {
              menu: {
                title: 'Users',
                hidden: false,                
              }
            }
          },
          {
            path: 'list-groups',
            data: {
              menu: {
                title: 'Groups',
                hidden: false,                
              }
            }
          },
          /*{
            path: 'account-settings',
            data: {
              menu: {
                title: 'Account',
                hidden: false,                
              }
            }
          },*/
        ]
      },
      {
        path: 'system',
        roles: [SYSTEM_ROLES.system],
        data: {
          menu: {
            title: 'System',
            icon: 'ion-settings',
            selected: false,
            expanded: false,
            order: 8,
            pathMatch: 'partial',
          },
        },
        children: [
          {
            path: 'customers',
            data: {
              menu: {
                title: 'Customers',
                hidden: false,
                pathMatch: 'partial',
              }
            }
          },
          {
            path: 'policy-templates',
            data: {
              menu: {
                title: 'Policy Templates',
                hidden: false,
                pathMatch: 'partial',            
              }
            }
          },
          {
            path: 'terms',
            data: {
              menu: {
                title: 'Terms Of Use',
                hidden: false,                
              }
            }
          },
          {
            path: 'scratch',
            data: {
              menu: {
                title: 'Scratch',
                hidden: false,                
              }
            }
          },
        ]
      },
    ]
  }
];
