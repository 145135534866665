export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyD3dha17fAk1p_h8Ll4KyBc6Gdeuyd_KeE",
    authDomain: "c2-web-91b0b.firebaseapp.com",
    databaseURL: "https://c2-web-91b0b.firebaseio.com",
    projectId: "c2-web-91b0b",
    storageBucket: "c2-web-91b0b.appspot.com",
    messagingSenderId: "2762449498",
    appId: "1:2762449498:web:fa3c6b9c31ebe2dc122e63",
    measurementId: "G-R8V9XTQCNN"
  },
  rollbarConfig: {
    accessToken: "38c6d81de98049f7a9c23ab80e723c19",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: "production",
      server: {
        root: "webpack:///"
      },
      client: {
        root: "webpack:///",
        javascript: {
          source_map_enabled: true, // true by default
          // -- Add this into your configuration ---
          code_version: "replace_me",
          // ---------------------------------------

          // Optionally have Rollbar guess which frames the error was 
          // thrown from when the browser does not provide line 
          // and column numbers.
          guess_uncaught_frames: true
        }
      }
    }
  },
  oldrollbarConfig: {
    accessToken: 'b4b3ae6e9e9541d0a11fffba8b1f4699',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'production',
  },
};