import { BaseModel } from '../../base/base.model';

export class UserStage extends BaseModel {
    lookupKey: string;
    firstname: string;
    lastname: string;
    roles: Array<string>;
    jobFunction: string;
    email: string;
    operation: string;
    errors: Array<string>;
    processedOn: Date;
    term: boolean;
    userId: string;

    constructor(user: any) {
        super();
        this.copy(user);
    }

    copy(that: any) {
        if ( that != null && that !== undefined ) {
            this.lookupKey = super.safeValueOrNull(that.lookupKey);
            this.firstname = super.safeValueOrNull(that.firstname);
            this.lastname = super.safeValueOrNull(that.lastname);
            this.roles = that.roles?that.roles:new Array<string>();
            this.jobFunction = super.safeValueOrNull(that.jobFunction);
            this.email = super.safeValueOrNull(that.email);
            this.operation = super.safeValueOrNull(that.operation);
            this.errors = that.errors || [];
            this.processedOn = super.fromFirestoreDate(that.processedOn);
            this.term = that.term? true : false;
            this.userId = super.safeValueOrNull(that.userId);
        }
    }
}
