import { NgModule, ModuleWithProviders, InjectionToken, Injectable, ErrorHandler, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { LoadingModule } from 'ngx-loading';

import { Autosize } from './components/autosize.directive';
import { UiSwitchComponent } from './components/ui-switch.component';
import { CollapsableComponent } from './components/collapsable/collapsable.component';
import { HoverDirective } from './components/hover.directive';
import { EmptyStateComponent } from './components/emptystate';
import { ItemListComponent } from './components/item-list/list.component';
import { FilterTableComponent } from './components/filter-table/table.component';
import { DropZoneDirective } from './components/dropzone.directive';
import { DragulaModule } from 'ng2-dragula';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DeleteConfirmationComponent } from './components/delete-confirmation/confirmation.component';
import { DragulaWrapper } from './components/dragula.wrapper';
import { AlertComponent } from './components/alert/alert.component';
  
@NgModule({
    declarations: [
        Autosize,
        UiSwitchComponent,
        CollapsableComponent,
        HoverDirective,
        EmptyStateComponent,
        ItemListComponent,
        FilterTableComponent,
        DropZoneDirective,
        DeleteConfirmationComponent,
        DragulaWrapper,
        AlertComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        Ng2SmartTableModule,
        LoadingModule,
        DragulaModule.forRoot(),
        SweetAlert2Module.forRoot({
            //cancelButtonClass: "btn btn-primary",
            //confirmButtonClass: "btn btn-primary",
        }),
    ],
    exports:[
        Autosize,
        UiSwitchComponent,
        CollapsableComponent,
        HoverDirective,
        EmptyStateComponent,
        ItemListComponent,
        FilterTableComponent,
        LoadingModule,
        DragulaModule,
        DropZoneDirective,
        SweetAlert2Module,
        DeleteConfirmationComponent,
        DragulaWrapper,
        AlertComponent,
    ],
    providers: [
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}