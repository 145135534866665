export class DataSchema {
    constructor() {
    }
    readonly DOCUMENT_KEY_DELIMITER: string = '_'; // NOTE: this is defined in the cloud functions also c2constants.
    
    // REALTIME DB PATHS
    readonly PATH_POLICIES: string = '/policies/';
    readonly PATH_POLICY_BODY: string = '/policies_body/';
    readonly PATH_POLICY_REVISION: string = '/policies_revisions/';
    readonly PATH_POLICY_BODY_DEFINITIONS: string = '/definitions/';
    readonly PATH_POLICY_TERMS: string = '/policies_terms/';
    readonly PATH_POLICY_CONTROLS: string = '/policies_controls/';
    readonly PATH_POLICY_PUBLISHED: string = '/policies_published_archive/';
    readonly PATH_POLICY_PUBLISHED_LOG: string = '/policies_published_log/';
    readonly PATH_POLICY_PUBLISHED_LIST: string = '/policies_published_list/';
    readonly PATH_POLICY_REVIEW: string = 'policies_reviews/';
    readonly PATH_POLICY_ASSIGNMENT: string = 'policies_assignments/';
    readonly PATH_POLICY_TEMPLATES: string = 'templates/';
    readonly PATH_POLICY_TEMPLATE_BODY: string = 'templates_body/';
    readonly PATH_POLICY_TEMPLATE_TERMS: string = 'templates_terms/';
    readonly PATH_POLICY_TEMPLATE_CONTROLS: string = 'templates_controls/'
    readonly PATH_ROLES: string = '/roles/';
    readonly PATH_PROCEDURES: string = '/procedures/';
    readonly PATH_PROCEDURE_TASKS: string = '/procedures_tasks/';
    readonly PATH_PROCEDURE_ASSIGNMENT: string = '/procedures_assignments/';
    readonly PATH_TRAINING: string = '/training/';
    readonly PATH_TRAINING_ASSIGNMENT: string = '/training_assignments/';
    readonly PATH_EXTERNAL_DOCUMENTS: string = '/documents/';
    readonly PATH_EXTERNAL_DOCUMENTS_ASSIGNMENT: string = '/documents_assignments/';
    
    // FIRESTORE COLLECTIONS
    readonly COLLECTION_APP_CONFIG: string = 'app_config/global/';
    readonly COLLECTION_POLICY_COMPLIANCE_SUMMARY: string = 'policy_compliance_summary/';
    readonly COLLECTION_USERS: string = 'users/';
    readonly COLLECTION_USER_FILE_LEDGER: string = 'user_file_ledger/';
    readonly COLLECTION_USER_FILE_LEDGER_STAGE: string = '/stage/';
    readonly COLLECTION_ASSIGNMENT: string = 'assignment/';
    readonly COLLECTION_USER_ASSIGNMENT: string = 'assignment_user_assignments/';
    readonly COLLECTION_ASSIGNMENT_HISTORY: string = 'assignment_history_log/';
    readonly COLLECTION_ASSIGNMENT_HISTORY_ASSIGNMENTS: string = '/user_assignments'
    readonly COLLECTION_ASSIGNMENT_CONTENT: string = 'assignment_content';
    readonly COLLECTION_CUSTOMERS: string = 'customers/';
    readonly COLLECTION_CUSTOMER_LOCATIONS: string = '/locations/';
    readonly COLLECTION_CUSTOMER_BILLING: string = '/billing/';
    readonly COLLECTION_CUSTOMER_CONTACTS: string = '/contacts/';
    readonly COLLECTION_TERMS_OF_USE: string = '/terms_of_use/';
    readonly COLLECTION_USER_TERMS: string = '/user_terms_accepted/';
    readonly COLLECTION_DISTRIBUTION_LIST: string = 'distribution_list/';
    
}
