import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class DataServiceFirebase {

    constructor(public afDatabase: AngularFireDatabase) {
    }

    /**
     * Finds a list 
     * 
     * @param path
     * @param opts -- optional for params on the query
     * 
     * @returns {Observable<any>}
     */
    public list(path: string, orderBy?: any, equalTo?: any): Observable<any> {
        return this.afDatabase.list(path, this.buildQuery(orderBy, equalTo)).snapshotChanges().pipe(map(items => {
            // Required to get the key as of AF 5.0
            return items.map( c => {
                let obj = c.payload.val();
                obj['$key'] = c.payload.key;
                return obj;
            })
        }));
    }
    
    /**
     * Builds a query for the list method
     * 
     * 
     * @param orderBy 
     * @param equalTo 
     */
    private buildQuery(orderBy?: any, equalTo?: any) {
        if ( orderBy && equalTo ) {
            return ref => ref.orderByChild(orderBy).equalTo(equalTo);    
        } else if ( orderBy ) {
            return ref => ref.orderByChild(orderBy);
        } else {
            return null;
        }
    }

    /**
     * Finds an object
     * @param path 
     * 
     * @returns {Observable<any>}
     */
    public findObject(path: string): Observable<any> {
        return this.afDatabase.object(path).snapshotChanges().pipe(map(item => {
            // Required to get the key as of AF 5.0
            let obj = item.payload.val();
            if ( obj ) {
                obj['$key'] = item.payload.key;
            }
            return obj;
        }));
    }

    /**
     * Finds an object 'once' without subscription, the return 
     * value from the promise is a snapshot and val() is required
     * to get the object. ex: 
     *  findObjectOnce(path).then( obj => {
     *      myobject = obj.val();
     *  });
     * 
     * @param path 
     * 
     * @returns {Promise<any>}
     */
    public findObjectOnce(path: string): Promise<any> {
        return new Promise( (resolve, reject) => {
            this.afDatabase.object(path).snapshotChanges().pipe(map( item => {
                // Required to get the key as of AF 5.0
                let obj = item.payload.val();
                if ( obj ) {
                    obj['$key'] = item.payload.key;
                }
                resolve(obj);
            }))
        })
    }
    
    /**
     * Puts an object to [path] using the supplied key and object,
     * this is useful for items where a relation exists such as 
     * the user profile, where the key to the object should be the
     * Firebase uid, or a policy body should have a key identical to
     * the policy header.
     * 
     * @param path 
     * @param key 
     * @param object 
     * 
     * @returns {Promise<any>}
     * 
     * @see {firebase.database.Reference.set}
     */
    public putObjectWithKey(path: string, key: string, object: any): Promise<any> {
        path += '/' + key;
        return this.afDatabase.object(path).set(object).catch(err => Promise.reject(err));;
    }

    /**
     * Updates an object.
     * 
     * @param path
     * @param obj
     * 
     * @returns {Promise<any>}
     */
    public saveObject(path: string, obj: any): Promise<any> {
        return this.afDatabase.object(path).update(obj).catch(err => Promise.reject(err));;
    }

    /**
     * Updates an array (object)
     * 
     * 
     * @param path 
     * @param object 
     */
    public saveArrayObject(path: string, object: any): Promise<void> {
        return this.afDatabase.object(path).set(object).catch(err => Promise.reject(err));;
    }

    /**
     * Removes an object from path
     * 
     * @param path 
     */
    public removeObject(path: string): Promise<any> {
        return this.afDatabase.object(path).remove().catch(err => Promise.reject(err));;
    }

    /**
     * Push/Persist an object to a location that is normally 
     * used as a list
     * 
     * @param path 
     * @param obj 
     */
    public push(path: string, obj: any): Promise<any> {
        const list = this.afDatabase.list(path).push(obj);
        return list.once('value').catch(err => Promise.reject(err));;
    }

    /**
     * Remove an object from a location that is normally
     * used as a list
     * 
     * @param path 
     * @param obj 
     */
    public remove(path: string, obj: any): Promise<any> {
        return this.afDatabase.list(path).remove(obj).catch(err => Promise.reject(err));;
    }

    public removeList(path: string): Promise<any> {
        return this.afDatabase.list(path).remove(null).catch(err => Promise.reject(err));;
    }
}

