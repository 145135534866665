<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">
  <!--<a routerLink="/pages/dashboard" class="al-logo clearfix"><span>Concentric</span>Compliance</a>-->
  <a href (click)="toggleMenu()" class="collapse-menu-link ion-navicon"></a>
  <a routerLink="/pages/dashboard" class="al-logo clearfix"><span>Concentric</span>Compliance</a>
  
  <div class="user-profile clearfix" >
    <div class="dropdown al-user-profile">
      <a class="profile-toggle-link dropdown-toggle "  id="user-profile-dd" data-toggle="dropdown" aria-expanded="false">
        <div class="content">
            <img  src="{{ ( 'app/typography/ConcentricGreen2.png' | baAppPicture ) }}" alt=""/>
            <div class="downcaret"><i class="fa fa-caret-down downcaret"></i></div>
        </div>
      </a>
      <ul class="dropdown-menu dropdown-menu-right top-dropdown-menu " aria-labelledby="user-profile-dd" data-offset="10,20">
        <!--<li class="dropdown-item"><a href><i class="fa fa-user"></i>Profile</a></li>
        <li class="dropdown-item"><a href><i class="fa fa-cog"></i>Settings</a></li>-->
        <li class="dropdown-item"><a class="signout" routerLink="/logout"><i class="fa fa-power-off"></i> Sign out</a></li>
      </ul>
    </div>
    
    <!--<ba-msg-center></ba-msg-center>-->
  </div>
</div> 