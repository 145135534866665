import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFireStorage } from '@angular/fire/storage';
import { LogService } from 'app/c2-core/services/log.service';

@Injectable()
export class StorageService {
    
    constructor(private storage: AngularFireStorage, private logService: LogService) {
    }

    /**
     * uploadAndWait()
     * 
     * Resolves promise with the download URL
     * 
     * 
     * @param file 
     * @param path 
     */
    uploadAndWait(file: File, path: string): Promise<string> {
        const task = this.storage.upload(path, file);
        return task.then( (snapshot) => {
            return snapshot.ref.getDownloadURL().then( url => {
                return Promise.resolve(url);
            });
        });
    }

    /**
     * Delete a file
     * 
     * 
     * @param path 
     */
    delete(path: string): Observable<any> {
        return this.storage.ref(path).delete();
    }
}