import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'app/c2-core/services/auth.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.authService.hasAllRoles(route.data.roles).catch(err => Promise.reject(err));
  }
}
