import { BaseModel } from '../../base/base.model';
import { Reseller } from './reseller.model';

export class Customer extends BaseModel {
    customerKey: string;
    effectiveDate: Date;
    endDate: Date;
    industry: string;
    name: string;
    shortName: string;
    casualName: string;
    referrer: string;
    website: string;
    reseller: Reseller;
    logoImagePath: string;
    logoImageURL: string;

    constructor(user: any) {
        super();
        this.copy(user);
    }

    copy(that: any) {
        if ( that != null && that !== undefined ) {
            this.customerKey = that.customerKey || that.$key;
            this.effectiveDate = super.fromFirestoreDate(that.effectiveDate);
            this.endDate = super.fromFirestoreDate(that.endDate);
            this.industry = that.industry || null;
            this.name = that.name  || null;
            this.casualName = that.casualName || null;
            this.shortName = that.shortName || null;
            this.referrer = that.referrer || null;
            this.website = that.website || null;
            this.reseller = that.reseller || null;
            this.logoImagePath = that.logoImagePath || null;
            this.logoImageURL = that.logoImageURL || null;
        }
    }
   
    toJSON() {
        return {
            customerKey: this.customerKey || null,
            casualName: this.casualName || null,
            effectiveDate: this.effectiveDate || null,
            endDate: this.endDate || null,
            industry: this.industry || null,
            name: this.name || null,
            referrer: this.referrer || null,
            shortName: this.shortName || null,
            website: this.website || null,
            reseller: this.reseller? this.reseller.toJSON(): null,
            logoImagePath: this.logoImagePath || null,
            logoImageURL: this.logoImageURL || null,
        };
    }
}
